import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import backdropSliceReducer from "./reducers/backdropSlice";
import chartDisplayReducer from "./reducers/chartDisplaySlice";
import destinationReducer from "./reducers/destinationsSlice";
import highlightedRouteReducer from "./reducers/highlightedRouteSlice";
import routeRiskReducer from "./reducers/routeRiskSlice";
import selectedRoutesReducer from "./reducers/selectedRoutesSlice";
import sliderReducer from "./reducers/sliderSlice";
import tokenReducer from "./reducers/tokenSlice";
import tstarReducer from "./reducers/tstarSlice";
import uploadedRouteReducer from "./reducers/uploadedRouteSlice";
import userRoutesReducer from "./reducers/userRoutesSlice";

export const store = configureStore({
  reducer: {
    risk: routeRiskReducer,
    highlightedRoute: highlightedRouteReducer,
    route: uploadedRouteReducer,
    userRoutes: userRoutesReducer,
    backdrop: backdropSliceReducer,
    chart: chartDisplayReducer,
    routes: selectedRoutesReducer,
    sliders: sliderReducer,
    token: tokenReducer,
    destinations: destinationReducer,
    tstar: tstarReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
