import { Accordion as MuiAccordion, AccordionProps } from "@material-ui/core";
import { styled } from "material-ui-core";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={1} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));