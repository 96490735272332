import { createSlice } from "@reduxjs/toolkit";

import TestRoute from "../../components/testRoutes/test_route_id-1.json";

const initialState = {
  route: TestRoute,
  routeName: "",
  routeId: 999999,
};

const uploadedRouteSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setRoute: (state: any, action: any) => {
      try {
        // Parse or directly assign the route payload
        state.route = action.payload;

        // Ensure `features` exist and is an array, otherwise push in default values
        // We'll want to handle this a better way in future
        if (Array.isArray(state.route.features)) {
          state.route.features.forEach((feature: any) => {
            if (
              feature.properties.color === "#4575b4" ||
              Object.keys(feature.properties).length === 0
            ) {
              feature.properties = {
                risk: 0,
                explanation: "No risk found for this segment. ",
                color: "#4575b4",
              };
            }
          });
        }
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteName: (state: any, action: any) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeName = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
    setRouteId: (state: any, action: any) => {
      try {
        // state.route = JSON.parse(action.payload);
        state.routeId = action.payload;
      } catch (e) {
        alert("Failed to upload route. Please try again.");
      }
    },
  },
});

export const { setRoute } = uploadedRouteSlice.actions;
export const { setRouteName } = uploadedRouteSlice.actions;
export const { setRouteId } = uploadedRouteSlice.actions;

export default uploadedRouteSlice.reducer;

export const getRoute = (state: any) => state.route.route;
export const getRouteName = (state: any) => state.route.routeName;
export const getRouteId = (state: any) => state.route.routeId;
