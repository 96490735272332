import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sliders: {
    bridge_overpass: 2.2,
    bridge_overwater: 3,
    bridge_overland: 2.3,
    tunnel: 5.5,
    rail: 6,
    curvature: 5.6,
    rural_population: 2,
    suburban_population: 3.5,
    urban_population: 5,
  },
  details: {
    bridge_overpass_details: null,
    bridge_overwater_details: null,
    bridge_overland_details: null,
    tunnel_details: null,
    rail_details: null,
    curvature_details: null,
    rural_population_details: null,
    suburban_population_details: null,
    urban_population_details: null,
  },
  visibility: {
    bridge_overpass: true,
    bridge_overwater: true,
    bridge_overland: true,
    tunnel: true,
    rail: true,
    curvature: true,
    rural_population: true,
    suburban_population: true,
    urban_population: true,
  },
  group: {
    bridge_group_expanded: false,
    population_group_expanded: false,
    road_group_expanded: false,
  },
};

const sliderSlice = createSlice({
  name: "sliders",
  initialState,
  reducers: {
    // Set slider values
    setBridgeOverpassSlider: (state: any, action: any) => {
      state.sliders.bridge_overpass = action.payload;
    },
    setBridgeOverWaterSlider: (state: any, action: any) => {
      state.sliders.bridge_overwater = action.payload;
    },
    setBridgeOverLandSlider: (state: any, action: any) => {
      state.sliders.bridge_overland = action.payload;
    },
    setTunnelSlider: (state: any, action: any) => {
      state.sliders.tunnel = action.payload;
    },
    setRailSlider: (state: any, action: any) => {
      state.sliders.rail = action.payload;
    },
    setCurvatureSlider: (state: any, action: any) => {
      state.sliders.curvature = action.payload;
    },
    setPopulationRuralSlider: (state: any, action: any) => {
      state.sliders.rural_population = action.payload;
    },
    setPopulationSuburbanSlider: (state: any, action: any) => {
      state.sliders.suburban_population = action.payload;
    },
    setPopulationUrbanSlider: (state: any, action: any) => {
      state.sliders.urban_population = action.payload;
    },

    // Set slider _details Details
    setBridgeOverpassSliderDetails: (state: any, action: any) => {
      state.sliders.bridge_overpass_details = action.payload;
    },
    setBridgeOverWaterSliderDetails: (state: any, action: any) => {
      state.sliders.bridge_overwater_details = action.payload;
    },
    setBridgeOverLandSliderDetails: (state: any, action: any) => {
      state.sliders.bridge_overland_details = action.payload;
    },
    setTunnelSliderDetails: (state: any, action: any) => {
      state.sliders.tunnel_details = action.payload;
    },
    setRailSliderDetails: (state: any, action: any) => {
      state.sliders.rail_details = action.payload;
    },
    setCurvatureSliderDetails: (state: any, action: any) => {
      state.sliders.curvature_details = action.payload;
    },
    setPopulationRuralSliderDetails: (state: any, action: any) => {
      state.sliders.rural_population_details = action.payload;
    },
    setPopulationSuburbanSliderDetails: (state: any, action: any) => {
      state.sliders.suburban_population_details = action.payload;
    },
    setPopulationUrbanSliderDetails: (state: any, action: any) => {
      state.sliders.urban_population_details = action.payload;
    },

    // Toggle visibility
    toggleBridgeOverpassVisibility: (state: any) => {
      state.visibility.bridge_overpass = !state.visibility.bridge_overpass;
    },
    toggleBridgeOverWaterVisibility: (state: any) => {
      state.visibility.bridge_overwater = !state.visibility.bridge_overwater;
    },
    toggleBridgeOverLandVisibility: (state: any) => {
      state.visibility.bridge_overland = !state.visibility.bridge_overland;
    },
    toggleTunnelVisibility: (state: any) => {
      state.visibility.tunnel = !state.visibility.tunnel;
    },
    toggleRailVisibility: (state: any) => {
      state.visibility.rail = !state.visibility.rail;
    },
    toggleCurvatureVisibility: (state: any) => {
      state.visibility.curvature = !state.visibility.curvature;
    },
    togglePopulationRuralVisibility: (state: any) => {
      state.visibility.rural_population = !state.visibility.rural_population;
    },
    togglePopulationSuburbanVisibility: (state: any) => {
      state.visibility.suburban_population =
        !state.visibility.suburban_population;
    },
    togglePopulationUrbanVisibility: (state: any) => {
      state.visibility.urban_population = !state.visibility.urban_population;
    },

    // Toggle group expanded
    setBridgeGroupExpanded: (state: any, action: any) => {
      state.group.bridge_group_expanded = action.payload;
    },
    setPopulationGroupExpanded: (state: any, action: any) => {
      state.group.population_group_expanded = action.payload;
    },
    setRoadGroupExpanded: (state: any, action: any) => {
      state.group.road_group_expanded = action.payload;
    },
    toggleBridgeGroupExpanded: (state: any) => {
      state.group.bridge_group_expanded = !state.group.bridge_group_expanded;
    },
    togglePopulationGroupExpanded: (state: any) => {
      state.group.population_group_expanded = !state.group.population_group_expanded;
    },
    toggleRoadGroupExpanded: (state: any) => {
      state.group.road_group_expanded = !state.group.road_group_expanded;
    }
  },
});

export const {
  setBridgeOverpassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setTunnelSlider,
  setRailSlider,
  setCurvatureSlider,
  setPopulationRuralSlider,
  setPopulationSuburbanSlider,
  setPopulationUrbanSlider,
  setBridgeOverpassSliderDetails,
  setBridgeOverWaterSliderDetails,
  setBridgeOverLandSliderDetails,
  setTunnelSliderDetails,
  setRailSliderDetails,
  setCurvatureSliderDetails,
  setPopulationRuralSliderDetails,
  setPopulationSuburbanSliderDetails,
  setPopulationUrbanSliderDetails,
  toggleBridgeOverpassVisibility,
  toggleBridgeOverWaterVisibility,
  toggleBridgeOverLandVisibility,
  toggleTunnelVisibility,
  toggleRailVisibility,
  toggleCurvatureVisibility,
  togglePopulationRuralVisibility,
  togglePopulationSuburbanVisibility,
  togglePopulationUrbanVisibility,
  setBridgeGroupExpanded,
  setPopulationGroupExpanded,
  setRoadGroupExpanded,
  toggleBridgeGroupExpanded,
  togglePopulationGroupExpanded,
  toggleRoadGroupExpanded,
} = sliderSlice.actions;

export default sliderSlice.reducer;

// Selectors for slider values
export const getBridgeOverpassSlider = (state: any) =>
  state.sliders.sliders.bridge_overpass;
export const getBridgeOverWaterSlider = (state: any) =>
  state.sliders.sliders.bridge_overwater;
export const getBridgeOverLandSlider = (state: any) =>
  state.sliders.sliders.bridge_overland;
export const getTunnelSlider = (state: any) => state.sliders.sliders.tunnel;
export const getRailSlider = (state: any) => state.sliders.sliders.rail;
export const getCurvatureSlider = (state: any) =>
  state.sliders.sliders.curvature;
export const getPopulationRuralSlider = (state: any) =>
  state.sliders.sliders.rural_population;
export const getPopulationSuburbanSlider = (state: any) =>
  state.sliders.sliders.suburban_population;
export const getPopulationUrbanSlider = (state: any) =>
  state.sliders.sliders.urban_population;

// Selectors for slider _details Details
export const getBridgeOverpassSliderDetails = (state: any) =>
  state.sliders.sliders.bridge_overpass_details;
export const getBridgeOverWaterSliderDetails = (state: any) =>
  state.sliders.sliders.bridge_overwater_details;
export const getBridgeOverLandSliderDetails = (state: any) =>
  state.sliders.sliders.bridge_overland_details;
export const getTunnelSliderDetails = (state: any) =>
  state.sliders.sliders.tunnel_details;
export const getRailSliderDetails = (state: any) =>
  state.sliders.sliders.rail_details;
export const getCurvatureSliderDetails = (state: any) =>
  state.sliders.sliders.curvature_details;
export const getPopulationRuralSliderDetails = (state: any) =>
  state.sliders.sliders.rural_population_details;
export const getPopulationSuburbanSliderDetails = (state: any) =>
  state.sliders.sliders.suburban_population_details;
export const getPopulationUrbanSliderDetails = (state: any) =>
  state.sliders.sliders.urban_population_details;

// Selectors for visibility
export const getBridgeOverpassVisibility = (state: any) =>
  state.sliders.visibility.bridge_overpass;
export const getBridgeOverWaterVisibility = (state: any) =>
  state.sliders.visibility.bridge_overwater;
export const getBridgeOverLandVisibility = (state: any) =>
  state.sliders.visibility.bridge_overland;
export const getTunnelVisibility = (state: any) =>
  state.sliders.visibility.tunnel;
export const getRailVisibility = (state: any) => state.sliders.visibility.rail;
export const getCurvatureVisibility = (state: any) =>
  state.sliders.visibility.curvature;
export const getPopulationRuralVisibility = (state: any) =>
  state.sliders.visibility.rural_population;
export const getPopulationSuburbanVisibility = (state: any) =>
  state.sliders.visibility.suburban_population;
export const getPopulationUrbanVisibility = (state: any) =>
  state.sliders.visibility.urban_population;

// Selectors for group expanded
export const getBridgeGroupExpanded = (state: any) => state.sliders.group.bridge_group_expanded;
export const getPopulationGroupExpanded = (state: any) => state.sliders.group.population_group_expanded;
export const getRoadGroupExpanded = (state: any) => state.sliders.group.road_group_expanded;
