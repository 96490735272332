import { Tooltip, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import BarChartIcon from "@material-ui/icons/BarChart";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect, useRef } from "react";

import { authFetch } from "../../auth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setBackdrop } from "../../redux/reducers/backdropSlice";
import { setChart } from "../../redux/reducers/chartDisplaySlice";
import {
  setRoute1,
  getRoute1,
  getVisibility,
} from "../../redux/reducers/selectedRoutesSlice";
import {
  getRouteName,
  setRouteName,
  setRoute as setLoadedRoute,
  getRouteId,
  setRouteId,
  getRoute as getLoadedRoute,
} from "../../redux/reducers/uploadedRouteSlice";
import {
  getUserRoutes,
  setUserRoutes,
} from "../../redux/reducers/userRoutesSlice";
import TestRoute from "../testRoutes/test_route_id-1.json";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(11),
    marginRight: theme.spacing(11),
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: "center",
  },
  popover: {
    pointerEvents: "none",
    width: 350,
  },
  paper: {
    padding: theme.spacing(1),
    width: 700,
    // height: 150,
  },
  popoverContent: {
    textAlign: "center",
  },
  routesList: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    border: "1px solid",
    // width: 400,
  },
  normalText: {
    fontWeight: "normal", // Set fontWeight to normal
  },
  zoomTooltip: {
    fontSize: "14px",
  },
}));

const RoutesTab: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [showNewRouteButton, setShowNewRouteButton] = useState(true);

  const route1Checked = useAppSelector(getRoute1);

  const loadedRoute = useAppSelector(getLoadedRoute);

  const routeName = useAppSelector(getRouteName);

  const routeId = useAppSelector(getRouteId);

  let userRoutes: [{ id: ""; name: "" }];
  userRoutes = useAppSelector(getUserRoutes);

  const visibilityState = useAppSelector(getVisibility);

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
    Cell_Coverage2: true,
    Incidents: true,
    Population: true,
    Rail_Junctions: true,
    Safe_Havens: true,
    Tunnels: true,
    Weather: true,
    Wildfires: true,
    checkbox1: true,
    LandscanUSA: false,
    LandscanGlobal: false,
    Mapbox_Traffic: false,
    Cell_Coverage: false,
    Cell_Coverage_ATT: false,
    Cell_Coverage_T_Mobile: false,
    Cell_Coverage_US_Cellular: false,
    Cell_Coverage_Verizon: false,
    NWS_Current_Warnings: false,
    NWS_Current_Watches: false,
    NWS_Wildfires: false,
    NWS_Weather_Radar: false,
    HIFLD_Hospitals: false,
    HIFLD_Police: false,
    HIFLD_Weigh_Stations: false,
    HIFLD_EMS: false,
    HIFLD_Tunnels: false,
    HIFLD_Rail_Juntions: false,
    HIFLD_Bridges: false,
  });

  const [open, setOpen] = React.useState(false);
  const [openSave, setOpenSave] = React.useState(false);

  const handleOpen = (value: number) => {
    if (value == 0) {
      setOpen(true);
    }
    if (value == 3) {
      //if a route is actually loaded here
      if (loadedRoute.features[0].properties.id != 999999999999) {
        setOpenSave(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSave(false);
  };

  function download(filename: string, text: string): void {
    filename = filename + ".geojson";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const exportRoute = (routeId: any) => {
    if (routeId == 1) {
      if (loadedRoute.features[0].properties.id != 999999999999) {
        download(routeName, JSON.stringify(loadedRoute));
      }
    }
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteRoute = () => {
    // Your delete logic here
    deleteRoute();
    setOpenDeleteDialog(false);
  };

  const deleteRoute = () => {
    setRouteToLoad(null);
    dispatch(setLoadedRoute(TestRoute));
    document.getElementById("route1Toggle")!.click();
    dispatch(setRouteName(null));

    // let routeId = loadedRoute.features[0].properties.route_id;
    const body = {
      routeId: routeId,
    };
    console.log(body);

    authFetch("/delete_route", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      console.log(res);
      const response = res.json();
      response.then(function (result: any) {
        authFetch("/user_routes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log(res);
          const response = res.json();
          response.then(function (result: any) {
            console.log(result);
            dispatch(setUserRoutes(result));
            handleClose();
          });
        });
      });
    });
  };

  const saveRoute = () => {
    const name = (document.getElementById("routename") as HTMLInputElement)
      .value;
    if (name !== "") {
      const weights = {
        // delaysWeight: 0,
        // cellProvider: "any",
        // bridgeOverpassWeight: bridgeOverpassSliderValue,
        // bridgeOverWaterWeight: bridgeOverWaterSliderValue,
        // bridgeOverLandWeight: bridgeOverLandSliderValue,
        // curvatureWeight: curvatureSliderValue,
        // populationRuralWeight: populationRuralSliderValue,
        // populationSuburbanWeight: populationSuburbanSliderValue,
        // populationUrbanWeight: populationUrbanSliderValue,
        // tunnelWeight: tunnelSliderValue,
        // railWeight: railSliderValue,
      };
      // let routeId = loadedRoute.features[0].properties.route_id;
      // console.log(loadedRoute)
      console.log(name, weights, routeId, 1);
      submitSaveRequest(name, weights, routeId, 1);
      handleClose();
      dispatch(setBackdrop({ status: "true", message: "Saving your route" }));
    } else {
      alert("Please enter a name to save the route");
    }
  };

  const submitSaveRequest = (
    name: any,
    weights: any,
    routeId: any,
    routeNumber: any
  ) => {
    // Call Alec's save endpoint. Pass id, weights, name
    const body = {
      routeId: routeId,
      name: name,
      // "weights": weights
    };
    console.log(body);

    authFetch("/update_nickname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      console.log(res);
      const response = res.json();
      response.then(function (result: any) {
        //If route is saved, we'll need to refresh the user's list of routes
        //Maybe the return of this endpoint could give all saved routes for that user?
        authFetch("/user_routes", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log(res);
          const response = res.json();
          response.then(function (result: any) {
            console.log(result);
            dispatch(setUserRoutes(result));
            handleClose();
            switch (routeNumber) {
              case 1:
                dispatch(setRouteName(name));
                break;
            }
          });
          dispatch(setBackdrop({ status: "false" }));
        });
      });
    });
    dispatch(setBackdrop({ status: "false" }));
  };

  const loadRoute = (value: { id: ""; name: "" } | null | undefined) => {
    let routeId = 0;
    let name = "";
    console.log(value);
    if (value != null) {
      routeId = parseInt(value.id);
      name = value.name;
    }
    console.log(routeId);

    if (routeId != 0) {
      dispatch(setBackdrop({ status: "true" }));
      dispatch(setRouteId(routeId));
      if (document.getElementById("loadRoute")) {
        (document.getElementById("loadRoute") as HTMLInputElement).value =
          routeId.toString();
        (document.getElementById("loadRoute") as HTMLInputElement).name =
          name.toString();
        document.getElementById("loadRoute")!.click();
      }
    }
  };

  const handleCheckboxChange2 = (event: any) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.name == "checkbox1") {
      document.getElementById("route1Toggle")!.click();
      // document.getElementById('normalizedRoute1Toggle')!.click();
      dispatch(setRoute1(event.target.checked));
    }
  };

  const zoomToBounds = () => {
    document.getElementById("zoomToBounds")!.click();
  };

  const showRouteControls = () => {
    setShowNewRouteButton(false); // Hides the button

    const geocoderContainers = document.querySelectorAll(
      ".mapboxgl-ctrl-geocoder"
    ) as NodeListOf<HTMLElement>;
    const generateRouteContainer = document.querySelector(
      ".generate-route-controls"
    ) as HTMLElement;

    // Make elements visible
    geocoderContainers.forEach((container) => {
      if (container) container.style.visibility = "visible";
    });
    if (generateRouteContainer) {
      generateRouteContainer.style.visibility = "visible";
    }

    // Add the emphasis effect
    geocoderContainers.forEach((container) => {
      if (container) container.classList.add("emphasized");
    });
    if (generateRouteContainer) {
      generateRouteContainer.classList.add("emphasized");
    }

    // Remove the emphasis after 3 seconds
    setTimeout(() => {
      geocoderContainers.forEach((container) => {
        if (container) container.classList.remove("emphasized");
      });
      if (generateRouteContainer) {
        generateRouteContainer.classList.remove("emphasized");
      }
    }, 3000); // 3-second emphasis
  };

  const resetRouteCheckbox = () => {
    console.log("RESET ROUTE CHECKBOX");
    // setState(prevState => {
    //   return {
    //     ...prevState,
    //     checkbox1: false
    //   };
    // });
  };

  const [routeToLoad, setRouteToLoad] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();
  const [origin, setOrigin] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();
  const [destination, setDestination] = React.useState<
    { id: ""; name: "" } | null | undefined
  >();

  const userActionRef = useRef(false);

  useEffect(() => {
    if (!userActionRef.current) {
      console.log(routeToLoad, "- Has changed");
      loadRoute(routeToLoad);
    } else {
      userActionRef.current = false; // Reset after handling the user-triggered change
    }
  }, [routeToLoad]); // Runs when `routeToLoad` changes

  //   useEffect(() => {
  //     // Ensure that the selected value is updated when userRoutes change
  //     if (routeToLoad) {
  //       const matchingRoute = userRoutes.find(
  //         (route) => route.id === routeToLoad.id
  //       );
  //       if (matchingRoute && routeToLoad.name !== matchingRoute.name) {
  //         // Indicate this change is not from user action
  //         userActionRef.current = true;
  //         // Update the state
  //         setRouteToLoad((prevRoute: any) => ({
  //           ...prevRoute,
  //           name: matchingRoute.name,
  //         }));
  //       }
  //     }
  //   }, [userRoutes]); // Runs when `userRoutes` changes

  function toggleChartView(value: any) {
    dispatch(setChart(value));
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <Autocomplete
            value={routeToLoad}
            onChange={(
              event: any,
              newValue: { id: ""; name: "" } | null | undefined
            ) => {
              setRouteToLoad(newValue);
            }}
            id="combo-box-demo"
            options={userRoutes}
            getOptionLabel={(option) => option!.name}
            style={{ width: "100%", marginTop: 10 }} // Change width to 100% for equal spacing
            renderInput={(params) => (
              <TextField {...params} label="Select Route" variant="outlined" />
            )}
          />
          <FormControl className={classes.formControl2}></FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent="center"
          alignItems="center"
        >
          {loadedRoute.features[0].properties.id != 999999999999 && <></>}

          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="delete-dialog-title"
          >
            <DialogTitle id="delete-dialog-title">Delete Route</DialogTitle>
            <DialogContent>
              Are you sure you want to permanently delete this route?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteRoute} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          {showNewRouteButton && (
            <Button
              variant="outlined"
              id="newRouteButton"
              onClick={() => showRouteControls()}
              startIcon={<AddIcon fontSize="small" />}
              size="small"
              className={classes.normalText}
            >
              New Route
            </Button>
          )}
        </Grid>

        {/* <Grid item xs={12} sm={4}>
            <InputLabel>Select View</InputLabel>
            <ToggleButtonGroup
              value={view}
              size="small"
              exclusive
              onChange={toggleView}
              aria-label="view"
              id="view"
            >
              <ToggleButton value="default" aria-label="Default">
                Default
              </ToggleButton>
              <ToggleButton value="normalized" aria-label="Normalized">
                Normalized
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
        {/* <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br> */}
      </Grid>
      {/*<Paper component="form">*/}
      {/* <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        > */}
      {/* <Grid item xs={9}> */}
      {/* <div id="geocoder"></div>
          <br></br>
          <div id="geocoder2"></div> */}
      {/* <Autocomplete
              value={origin}
              freeSolo
              // onChange={(event: any, newValue: { id: ""; name: ""; } | null | undefined)  => {
              //   // setValue2(newValue);
              // }}
              id="start-location"
              options={destinations}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 10, display: "inline" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Starting Location"
                  variant="outlined"
                />
              )}
              // onChange={handleChange}
            /> */}
      {/* </Grid> */}
      {/* <Grid item xs={1}>
            <Tooltip
              title="Click on the map to select a starting location"
              placement="right"
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => setLocationSelection("startLocation")}
                >
                  <AddLocationIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        > */}
      {/* <Grid item xs={9}>
            <Autocomplete
              value={destination}
              freeSolo
              // onChange={(event: any, newValue: { id: ""; name: ""; } | null | undefined)  => {
              //   // setValue2(newValue);
              // }}
              id="destination"
              options={destinations}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 10, display: "inline" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Destination"
                  variant="outlined"
                />
              )}
              // onChange={handleChange}
            />
          </Grid> */}
      {/* <Grid item xs={1}>
            <Tooltip
              title="Click on the map to select a destination"
              placement="right"
            >
              <div>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={() => setLocationSelection("destinationBtn")}
                >
                  <AddLocationIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid> */}
      {/* </Grid> */}
      <br></br>
      {/* <Button
          variant="contained"
          id="runVulBtn"
          color="primary"
          onClick={() => generateRoute()}
        >
          Generate Route
        </Button> */}
      {/* <br></br>
        <br></br>
        <br></br> */}
      {/* <Divider /> */}
      <br></br>
      <List className={classes.routesList}>
        {loadedRoute.features[0].properties.id != 999999999999 && (
          <Grid container spacing={3}>
            <ListItem
              id="route1ListItem"
              key={0}
              role={undefined}
              dense
              button
              style={{ visibility: visibilityState ? "visible" : "hidden" }}
            >
              <Tooltip
                title="Show/Hide Route on Map"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <ListItemIcon>
                  <Checkbox
                    id="checkbox1"
                    name="checkbox1"
                    edge="start"
                    checked={route1Checked}
                    tabIndex={-1}
                    disableRipple
                    onClick={handleCheckboxChange2}
                  />
                </ListItemIcon>
              </Tooltip>
              <Grid item xs>
                {routeName}
              </Grid>
              <Tooltip
                title="Zoom to Bounds"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  onClick={zoomToBounds}
                  aria-label="save"
                  edge="end"
                  style={{ paddingLeft: 12 }}
                >
                  <ZoomInIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Edit Route Name"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  aria-label="edit"
                  style={{ paddingLeft: 12 }}
                  edge="end"
                  onClick={() => handleOpen(3)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Delete Route"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  aria-label="delete"
                  style={{ paddingLeft: 12 }}
                  edge="end"
                  onClick={handleOpenDeleteDialog}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>

              {/* <Tooltip
                title="Show Line Chart View"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  onClick={() => toggleChartView(0)}
                  edge="end"
                  aria-label="comments"
                  style={{ paddingLeft: 12 }}
                >
                  <ShowChartIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title="Show Bar Chart View"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  onClick={() => toggleChartView(1)}
                  edge="end"
                  aria-label="comments"
                  style={{ paddingLeft: 12 }}
                >
                  <BarChartIcon />
                </IconButton>
              </Tooltip> */}

              <Tooltip
                title="Export Route"
                classes={{ tooltip: classes.zoomTooltip }}
              >
                <IconButton
                  edge="end"
                  aria-label="show-hide"
                  onClick={() => exportRoute(1)}
                >
                  {/* Add an icon here */}
                </IconButton>
              </Tooltip>
              {/*<IconButton edge="end" aria-label="show-hide">
              <VisibilityIcon />
            </IconButton>*/}
            </ListItem>
          </Grid>
        )}
      </List>

      <Dialog
        open={openSave}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Save Route</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <TextField
            autoFocus
            margin="dense"
            id="routename"
            label="Name Your Route"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveRoute} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        style={{ visibility: "hidden" }}
        id="reset_route_checkbox"
        color="inherit"
        onClick={resetRouteCheckbox}
      ></Button>
    </div>
  );
};

export default RoutesTab;
