import React, { useEffect } from "react";
import "./App.css";

import { useAuth } from "./auth";
import Dashboard from "./Dashboard";
import Login from "./Login";
import { useAppDispatch } from "./redux/hooks";
import {
  setBridgeOverpassSliderDetails,
  setBridgeOverWaterSliderDetails,
  setBridgeOverLandSliderDetails,
  setCurvatureSliderDetails,
  setPopulationRuralSliderDetails,
  setPopulationSuburbanSliderDetails,
  setPopulationUrbanSliderDetails,
  setTunnelSliderDetails,
  setRailSliderDetails,
} from "./redux/reducers/sliderSlice";

export default function App() {
  const [authed] = useAuth();

  useEffect(() => {
    // Clear localStorage items here
    localStorage.removeItem("origin");
    localStorage.removeItem("destination");
    localStorage.removeItem("waypoints");

    // Any other initialization logic
  }, []); // Empty dependency array ensures this runs only once on mount

  const dispatch = useAppDispatch();
  //fetch and get the slider details on page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("./risk_stats");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        dispatch(setBridgeOverpassSliderDetails(data?.["Bridge Overpasses"]));
        dispatch(setBridgeOverWaterSliderDetails(data?.["Bridge Over Water"]));
        dispatch(setBridgeOverLandSliderDetails(data?.["Bridge Over Land"]));
        dispatch(setCurvatureSliderDetails(data?.["Winding Narrow Roads"]));
        dispatch(setPopulationRuralSliderDetails(data?.["Population: Rural"]));
        dispatch(
          setPopulationSuburbanSliderDetails(data?.["Population: Suburban"])
        );
        dispatch(setPopulationUrbanSliderDetails(data?.["Population: Urban"]));
        dispatch(setTunnelSliderDetails(data?.["Tunnels (small or large)"]));
        dispatch(setRailSliderDetails(data?.["Railroad Crossing"]));
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures it runs only once

  return authed ? (
    <div className="App">
      <Dashboard />
    </div>
  ) : (
    <div className="App">
      <Login />
    </div>
  );
}
