import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { AccordionDetails, AccordionSummary } from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { Accordion } from "../../../../consts/Accordion";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import "./RiskSettingsGroupView.css";

interface IRiskSettingsGroupViewProps {
  title: string,
  getExpanded: any,
  toggleExpanded: Function,
  children?: React.ReactNode,
}

const RiskSettingsGroupView: React.FC<IRiskSettingsGroupViewProps> = (props: IRiskSettingsGroupViewProps) => {
  const dispatch = useAppDispatch();
  const expanded: boolean = useAppSelector(props.getExpanded);

  const toggleExpanded = () => {
    dispatch(props.toggleExpanded());
  }

  return (
    <Accordion className="risk-settings-group-view" expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary className="risk-settings-group-view-header" expandIcon={<ArrowDropDown />}>
        <Typography component="span">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails className="risk-settings-group-view-content-wrapper">
        <div className="risk-settings-group-view-content">
          {props.children}
        </div>
      </AccordionDetails>
    </Accordion>
  )
};

export default RiskSettingsGroupView;
