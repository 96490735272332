import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  route1Checked: true,
  visibilityState: true,
};

const selectedRoutesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRoute1: (state: any, action: any) => {
      state.route1Checked = action.payload;
      console.log(state.route1Checked);
    },
    setVisibility: (state: any, action: any) => {
      state.visibilityState = action.payload;
      console.log(state.visibilityState);
    },
  },
});

export const { setRoute1 } = selectedRoutesSlice.actions;
export const { setVisibility } = selectedRoutesSlice.actions;

export default selectedRoutesSlice.reducer;

export const getRoute1 = (state: any) => state.routes.route1Checked;
export const getVisibility = (state: any) => state.routes.visibilityState;
