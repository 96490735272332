import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { RiskSettingsSlider } from "../../../../../../consts/RiskSettingsSlider";

interface IRiskSettingSliderViewProps {
  title: string,
  markDataIndex: number,
  getVisible: any,
  getControlDetails: any,
  getValue: any,
  setValue: Function,
  marks: {value: number, label?: string}[],
}

const RiskSettingSliderView: React.FC<IRiskSettingSliderViewProps> = (props: IRiskSettingSliderViewProps) => {
  const dispatch = useAppDispatch();

  // Update slider value
  const changeSliderValue = (event: any, value: any) => {
    dispatch(props.setValue(value));
  };

  // Custom MUI slider
  const controlDetails: any = useAppSelector(props.getControlDetails);
  const value: any = useAppSelector(props.getValue);
  const visible: boolean = useAppSelector(props.getVisible);
  const CustomControlSlider = RiskSettingsSlider(controlDetails, props.markDataIndex);

  return (
    <CustomControlSlider
      track={false}
      onChangeCommitted={changeSliderValue}
      defaultValue={value}
      aria-labelledby="discrete-slider"
      valueLabelDisplay="auto"
      step={0.1}
      marks={props.marks}
      min={1.0}
      max={10.0}
      disabled={!visible}
    />
  )
};

export default RiskSettingSliderView;
