import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { login, authFetch } from "./auth";
import { useAppDispatch } from "./redux/hooks";
import { setToken } from "./redux/reducers/tokenSlice";
import { setUserRoutes } from "./redux/reducers/userRoutesSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/login_image.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logo_image: {
    width: "100%",
    paddingBottom: 20,
  },
  paper: {
    padding: theme.spacing(8, 8),
    paddingTop: theme.spacing(25),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    // width: '40%',
    margin: theme.spacing(1),
  },
  text: {
    margin: theme.spacing(1),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [accountReason, setAccountReason] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const [success, showSuccess] = useState(false);
  const [failure, showFailure] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onChange(value: any) {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
  }

  function checkIfLoggedIn() {
    fetch("./user/status", {
      method: "get",
    })
      .then((r) => r.json())
      .then((token) => {
        console.log(token);
        if (token.token) {
          dispatch(setToken({ token: token.token }));
          login(token);
          fetchUserRoutes();
        } else {
          console.log("not logged in");
        }
      });
  }

  function fetchUserRoutes() {
    //fetching logged in user's routes
    authFetch("/user_routes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res: any) => {
      console.log(res);
      const response = res.json();
      response.then(function (result: any) {
        console.log(result);
        dispatch(setUserRoutes(result));
      });
    });
  }

  //checking if user is logged in only on load of the page
  window.onload = checkIfLoggedIn;

  const onSubmitClick = (e: any) => {
    //redirecting to microsoft authentication
    //TODO: This will need to be addressed on production
    // window.location.href  = "https://gshs-securoutedev.ornl.gov/user/?sso";
    // window.location.href = process.env.REACT_APP_FRONTEND_HOST! + "/user/?sso";
    const fetchRedirectUrl = async () => {
      try {
        const response = await fetch("./hosts");
        if (response.ok) {
          const data = await response.json();
          window.location.href = data.backend_host + "/user/?sso";
        } else {
          console.error("Failed to fetch redirect URL");
        }
      } catch (error) {
        console.error("Error fetching redirect URL:", error);
      }
    };

    fetchRedirectUrl();
  };

  const onRequestAccountClick = (e: any) => {
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      organization: organization,
      accountReason: accountReason,
    };

    const validated = validate(body);
    console.log(validated);

    if (validated) {
      setCaptchaValue("");
      fetch("/request_acct", {
        method: "post",
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((response) => {
          console.log(response);
          if (response == null) {
            showSuccess(true);
            handleClose();
          } else {
            console.log("error");
          }
        });
      showSuccess(true);
      handleClose();
    } else {
      //if input failed to validate, return error message and don't send email
      showFailure(true);
    }
  };

  //check if any of the account request fields are blank
  const validate = (body: any) => {
    if (captchaValue) {
      console.log(body);
      let response = true;
      for (const field in body) {
        if (`${body[field]}`.toString() == "") {
          console.log("field missing");
          response = false;
        }
      }
      return response;
    }
  };

  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleFirstNameChange = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleOrganizationChange = (e: any) => {
    setOrganization(e.target.value);
  };

  const handleAccountReasonChange = (e: any) => {
    setAccountReason(e.target.value);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src="/sr_logo_black.png" className={classes.logo_image} />
          <Typography variant="h6" align="left">
            SecuRoute is a tool developed by ORNL to help security planners
            develop secure and safe routes for moving high-consequence shipments
            within the United States. SecuRoute is a web-based platform that
            contextualizes nationwide risk context for any planned in-country
            route. The platform analyzes static and real-time route conditions
            and provides users with risk summaries, including detailed risk
            factors, for each route segment.
          </Typography>
          <form className={classes.form} noValidate method="post">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmitClick}
              startIcon={<ExitToAppIcon />}
            >
              Sign In With Microsoft
            </Button>
            {/*<Link href="#" variant="body2" style={{float: "left"}}>
              Forgot password?
            </Link>*/}
            <Link
              onClick={handleOpen}
              variant="body2"
              style={{ float: "right" }}
            >
              {"New User? Sign Up"}
            </Link>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paperModal}>
                  <h2 id="transition-modal-title">Request Account</h2>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    // autoComplete="first-name"
                    onChange={handleFirstNameChange}
                    value={firstName}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="last"
                    // autoComplete="name"
                    onChange={handleLastNameChange}
                    value={lastName}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    id="email"
                    // autoComplete="email"
                    onChange={handleEmailChange}
                    value={email}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="organization"
                    label="Organization"
                    id="organization"
                    // autoComplete="organization"
                    onChange={handleOrganizationChange}
                    value={organization}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="accountReason"
                    label="Reason For Account"
                    id="accountReason"
                    // autoComplete="accountReason"
                    onChange={handleAccountReasonChange}
                    value={accountReason}
                  />
                  <Button
                    style={{ float: "right" }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={onRequestAccountClick}
                  >
                    Request Account
                  </Button>
                  <Button
                    style={{ float: "right" }}
                    type="submit"
                    variant="contained"
                    // color="secondary"
                    className={classes.submit}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <ReCAPTCHA
                    sitekey="6LeZomUhAAAAAAYvqT8woO1f33h_OzkzOoK4nl4y"
                    onChange={onChange}
                  />
                  <Snackbar
                    open={failure}
                    autoHideDuration={6000}
                    onClose={() => showFailure(false)}
                  >
                    <MuiAlert severity="error">
                      One or more required fields are missing
                    </MuiAlert>
                  </Snackbar>
                </div>
              </Fade>
            </Modal>
          </form>
        </div>
        <Snackbar
          open={success}
          autoHideDuration={6000}
          onClose={() => showSuccess(false)}
        >
          <MuiAlert severity="success">Account Requested</MuiAlert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
