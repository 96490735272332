import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getBackdrop, setBackdrop } from "../../redux/reducers/backdropSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    message: {
      marginBottom: theme.spacing(2),
      fontSize: "1.2rem",
      textAlign: "center",
      color: "#fff",
    },
  })
);

function SimpleBackdrop() {
  const dispatch = useAppDispatch();
  const backdropStatus = useAppSelector(getBackdrop);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [ellipsis, setEllipsis] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsis((prev) => (prev.length < 3 ? prev + "." : ""));
    }, 500); // Adjust speed as desired

    return () => clearInterval(interval);
  }, []);

  const handleClose = () => {
    setOpen(false);
    //ability to click through backdrop if you need to skip it
    dispatch(setBackdrop({ status: "false" }));
  };

  if (open === false && backdropStatus.status === "true") {
    setOpen(true);
  }
  if (open === true && backdropStatus.status === "false") {
    setOpen(false);
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        {backdropStatus.message && (
          <div className={classes.message}>
            {backdropStatus.message}
            {ellipsis}
          </div>
        )}
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default SimpleBackdrop;
