import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React from "react";

import MapLayersTab from "./MapLayersTab";
import RoutesTab from "./RoutesTab";
import TstarTab from "./TstarTab";
import RiskControlsView from "./RiskSettingsView/RiskSettingsView";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(11),
    marginRight: theme.spacing(11),
  },
  paper: {
    padding: theme.spacing(1),
    width: 700,
    // height: 150,
  },
  routesList: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    border: "1px solid",
    // width: 400,
  },
}));

export default function SimpleTabs() {
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue == 0) {
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Routes" {...a11yProps(0)} />
          <Tab label="Risk Settings" {...a11yProps(1)} />
          <Tab label="Map Layers" {...a11yProps(2)} />
          {/* <Tab label="T-STAR Live Data" {...a11yProps(3)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <RoutesTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RiskControlsView />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MapLayersTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TstarTab />
      </TabPanel>
    </div>
  );
}
