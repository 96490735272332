import { Slider, withStyles } from "@material-ui/core";

const gradientMapping: Record<number, string> = {
  0: "0%",
  1: "0%",
  1.2: "2.7%", // Interpolated between 1 and 2
  2: "11.25%",
  2.8: "17.8125%", // Interpolated between 2 and 3
  3: "22.375%",
  3.3: "25.725%",
  3.7: "30.1125%", // Interpolated between 3.3 and 4
  4: "33.5%",
  4.3: "38.0625%", // Interpolated between 4 and 5
  4.7: "41.5625%", // Interpolated between 4 and 5
  5: "44.625%",
  6: "55.75%",
  7: "66.875%",
  8: "78%",
  9: "89%",
  9.1: "90.1%", // Interpolated between 9 and 10
  10: "100%",
};

export const RiskSettingsSlider = (railDataIndex: any, markDataIndex: number) => {
  return withStyles({
    root: {},
    thumb: {
      "&:focus, &:hover, &$active": {},
    },
    mark: {
      height: 10,
      width: 1,
      marginTop: -2,
      [`&[data-index="${markDataIndex}"]`]: {
        backgroundColor: "black",
        height: 20,
        width: 2,
        marginTop: -6,
        marginLeft: -1,
      },
    },
    active: {},
    rail: {
      height: 6,
      background: `linear-gradient(to right, black ${
        gradientMapping[0]
      }, black ${gradientMapping[railDataIndex?.["mid_20"] || 0]}, green ${
        gradientMapping[railDataIndex?.["mid_20"] || 0]
      }, green ${gradientMapping[railDataIndex?.["mid_80"] || 0]}, black ${
        gradientMapping[railDataIndex?.["mid_80"] || 0]
      }, black 100%)`,
    },
  })(Slider);
}