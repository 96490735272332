import React from "react";

import "./App.css";
import { logout, useAuth, login, authFetch } from "./auth";
import Backdrop from "./components/backdrop/Backdrop";
import PersistentDrawer from "./components/drawer/PersistentDrawer";
// import { MultipleRiskAreaChart } from "./components/charts/MultipleRiskAreaChart";
import { useAppDispatch } from "./redux/hooks";
import { setToken } from "./redux/reducers/tokenSlice";
import { setUserRoutes } from "./redux/reducers/userRoutesSlice";

function Dashboard() {
  const [authed] = useAuth();
  const dispatch = useAppDispatch();

  function refreshLoginToken() {
    fetch("./user/status", {
      method: "get",
    })
      .then((r) => r.json())
      .then((token) => {
        console.log(token);
        if (token.token) {
          console.log("refreshing login");
          dispatch(setToken({ token: token.token }));
          login(token);

          //fetching logged in user's routes
          authFetch("/user_routes", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res);
            const response = res.json();
            response.then(function (result: any) {
              console.log(result);
              dispatch(setUserRoutes(result));
            });
          });
        } else {
          console.log("failed to refresh login");
          logout();
        }
      });
  }

  //on load of the page, refresh the JWT token
  window.onload = refreshLoginToken;

  return (
    <React.Fragment>
      <PersistentDrawer />
      <Backdrop />
    </React.Fragment>
  );
}

export default Dashboard;
