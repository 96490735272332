import { makeStyles, Theme } from "material-ui-core";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  popover: {
    pointerEvents: "none",
    width: 350,
  },
  paper: {
    padding: theme.spacing(1),
    width: 700,
    // height: 150,
  },
  dropdown: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    border: "1px solid",
    // width: 400,
  },
  zoomTooltip: {
    fontSize: "14px",
  },
}));

// export const styleClasses = useStyles();