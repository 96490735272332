import mapboxgl from "mapbox-gl";
import Plotly from "plotly.js";
import Plot from "react-plotly.js";

import { useAppSelector } from "../../redux/hooks";
import { getRoute } from "../../redux/reducers/uploadedRouteSlice";

const PlotlyBarCart = () => {
  const loadedRoute = useAppSelector(getRoute);

  if (!loadedRoute || !loadedRoute.features) {
    return <div>Loading...</div>;
  }

  const risks = loadedRoute.features.map(
    (feature: any) => feature.properties.risk
  );

  // Define human-readable labels for the factors
  const factorLabels: { [key: string]: string } = {
    bridge_overwater: "Bridge Over Water",
    bridge_overland: "Bridge Over Land",
    bridge_overpass: "Bridge Overpass/Underpass",
    curvature: "Winding Narrow Roads",
    rural_population: "Population: Rural",
    suburban_population: "Population: Suburban",
    urban_population: "Population: Urban",
    rail: "Rail Crossing",
    tunnel: "Tunnels",
  };

  // Grabbing all the properties from the segments
  const indices = Array.from({ length: risks.length }, (_, index) => index);
  const geometry = loadedRoute.features.map((feature: any) => feature.geometry);
  const id = loadedRoute.features.map((feature: any) => feature.id);
  const color = loadedRoute.features.map(
    (feature: any) => feature.properties.color
  );

  const explanation = loadedRoute.features.map((feature: any) => {
    const explanationData = feature.properties.explanation;

    if (explanationData) {
      if (explanationData === "No risk found for this segment. ") {
        return "No risk features of concern were found in this area";
      }

      // Sort factors by their human-readable labels
      const formattedExplanation = Object.entries(explanationData)
        .sort(([factorA], [factorB]) => {
          const labelA = factorLabels[factorA] || factorA.replace(/_/g, " ");
          const labelB = factorLabels[factorB] || factorB.replace(/_/g, " ");
          return labelA.localeCompare(labelB); // Sort alphabetically by human-readable label
        })
        .map(([factor, scores]) => {
          const humanReadableFactor =
            factorLabels[factor] || factor.replace(/_/g, " ");
          const formattedScores = Array.isArray(scores) ? scores[0] : scores;
          return `${humanReadableFactor}: ${formattedScores}`;
        })
        .join("<br>");

      return formattedExplanation;
    }

    return "";
  });

  const colorLegend = [
    { value: 0, color: "#4575b4", label: "0: No Concerns" },
    { value: 1, color: "#91bfdb", label: "1: Very Low" },
    { value: 2, color: "#e0f3f8", label: "2: Low" },
    { value: 3, color: "#ffffbf", label: "3: Moderate" },
    { value: 4, color: "#fee090", label: "4: High" },
    { value: 5, color: "#fc8d59", label: "5: Very High" },
    { value: 6, color: "#d73027", label: "6: No Go" },
  ];

  // Function to get the label from color legend based on risk value
  const getRiskLabel = (risk: number) => {
    const entry = colorLegend.find((entry) => entry.value === risk);
    return entry ? `${entry.label}` : "Unknown Risk"; // Updated format
  };

  const handleBarClick = (event: any) => {
    if (event && event.points && event.points.length > 0) {
      const clickedPoint = event.points[0];
      const segmentIndex = clickedPoint.x;

      // console.log("Clicked Bar Details:", {
      //   segmentIndex: segmentIndex,
      //   riskCategory: clickedPoint.y,
      //   geometry: geometry[segmentIndex], // Access the corresponding geometry
      //   id: id[segmentIndex], // Access the corresponding id
      // });

      const coords: any[] = [];
      const coordinates = geometry[segmentIndex].coordinates;

      coordinates.forEach((coordinate: any) => coords.push(coordinate));

      const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
      coords.forEach((coord) => bounds.extend(coord));

      const bbox = `${bounds.getWest()}, ${bounds.getSouth()}, ${bounds.getEast()}, ${bounds.getNorth()}`;
      document.getElementById("BOUND")!.setAttribute("data-bound", bbox);
      document
        .getElementById("SEGMENTID")!
        .setAttribute("segment-id", id[segmentIndex]);

      document.getElementById("LINK_SELECTED")!.click();
    }
  };

  const handleBarHover = (event: any) => {
    if (event && event.points && event.points.length > 0) {
      const hoveredPoint = event.points[0];
      const segmentIndex = hoveredPoint.x;

      // console.log("Hovered Bar Details:", {
      //   segmentIndex: segmentIndex,
      //   riskCategory: hoveredPoint.y,
      //   geometry: geometry[segmentIndex], // Access the corresponding geometry
      //   id: id[segmentIndex], // Access the corresponding id
      //   explanation: explanation[segmentIndex], // Explanation data
      //   color: color[segmentIndex], // Color for the risk category
      // });

      document
        .getElementById("SEGMENTID")!
        .setAttribute("segment-id", id[segmentIndex]);
      document.getElementById("LINK_HOVERED")!.click();
    }
  };

  const handleBarUnhover = (event: any) => {
    if (event && event.points && event.points.length > 0) {
      const hoveredPoint = event.points[0];
      const segmentIndex = hoveredPoint.x;

      // console.log("Hovered Bar Details:", {
      //   segmentIndex: segmentIndex,
      //   riskCategory: hoveredPoint.y,
      //   geometry: geometry[segmentIndex], // Access the corresponding geometry
      //   id: id[segmentIndex], // Access the corresponding id
      //   explanation: explanation[segmentIndex], // Explanation data
      //   color: color[segmentIndex], // Color for the risk category
      // });

      document
        .getElementById("SEGMENTID")!
        .setAttribute("segment-id", id[segmentIndex]);
      document.getElementById("LINK_UNHOVERED")!.click();
    }
  };

  const handleRelayout = (event: any) => {
    // Handle the case where the x-axis range is updated (for zooming or panning)
    if (event["xaxis.range"]) {
      const [xMin, xMax] = event["xaxis.range"];

      // Assuming `indices` and `id` are arrays of the segment indices and IDs, filter based on axis range
      // Filter indices based on the visible x-axis range
      const visibleSegments = indices
        .map((index) => ({
          segmentIndex: index,
          segmentId: id[index], // Get corresponding segment ID
        }))
        .filter(
          ({ segmentIndex }) => segmentIndex >= xMin && segmentIndex <= xMax
        )
        .map(({ segmentId }) => segmentId); // Extract only the segment IDs

      // Define the type for unfocusedSegments explicitly as string[]
      const allSegments: string[] = id; // Assuming `id` contains all the segment IDs as strings
      const unfocusedSegments: string[] = allSegments.filter(
        (segmentId) => !visibleSegments.includes(segmentId)
      );

      // Pass the unfocused segments to the DOM element
      document
        .getElementById("UNFOCUSEDSEGMENTS")!
        .setAttribute("unfocused-segments", JSON.stringify(unfocusedSegments));

      // Ensure this element exists
      const styleElement = document.getElementById("STYLE_UNFOCUSED");
      if (styleElement) {
        styleElement.click();
      } else {
        console.error("STYLE_UNFOCUSED element not found");
      }
    }

    // Handle the case where axes have been reset (i.e., range is undefined)
    else if (
      event["xaxis.range[0]"] === undefined ||
      event["xaxis.range[1]"] === undefined
    ) {
      // Handle the reset logic: all segments are unfocused
      const allSegments: string[] = id; // Assuming `id` contains all the segment IDs as strings
      const unfocusedSegments: string[] = []; // After reset, all segments are unfocused

      // Pass the unfocused segments to the DOM element
      document
        .getElementById("UNFOCUSEDSEGMENTS")!
        .setAttribute("unfocused-segments", JSON.stringify(unfocusedSegments));

      // Ensure this element exists
      const styleElement = document.getElementById("STYLE_UNFOCUSED");
      if (styleElement) {
        styleElement.click();
      } else {
        console.error("STYLE_UNFOCUSED element not found");
      }
    } else {
      // In case of any other layout changes that don't involve range updates
      // console.log("Unhandled event in handleRelayout", event);
    }

    // Handle zooming in and out (when xaxis.range[0] and xaxis.range[1] are available)
    if (
      event["xaxis.range[0]"] !== undefined &&
      event["xaxis.range[1]"] !== undefined
    ) {
      const xMin = event["xaxis.range[0]"];
      const xMax = event["xaxis.range[1]"];

      // Assuming `indices` and `id` are arrays of the segment indices and IDs, filter based on axis range
      const visibleSegments = indices
        .map((index) => ({
          segmentIndex: index,
          segmentId: id[index], // Get corresponding segment ID
        }))
        .filter(
          ({ segmentIndex }) => segmentIndex >= xMin && segmentIndex <= xMax
        )
        .map(({ segmentId }) => segmentId); // Extract only the segment IDs

      // Define the type for unfocusedSegments explicitly as string[]
      const allSegments: string[] = id; // Assuming `id` contains all the segment IDs as strings
      const unfocusedSegments: string[] = allSegments.filter(
        (segmentId) => !visibleSegments.includes(segmentId)
      );

      // Pass the unfocused segments to the DOM element
      document
        .getElementById("UNFOCUSEDSEGMENTS")!
        .setAttribute("unfocused-segments", JSON.stringify(unfocusedSegments));

      // Ensure this element exists
      const styleElement = document.getElementById("STYLE_UNFOCUSED");
      if (styleElement) {
        styleElement.click();
      } else {
        console.error("STYLE_UNFOCUSED element not found");
      }
    }
  };

  return (
    <Plot
      data={[
        {
          x: indices,
          y: risks,
          type: "bar",
          marker: { color: color },
          hovertemplate:
            "<b>Risk Category Score:</b><br>%{customdata[0]}<br><br>" +
            "<b>Contributing Risk Factors:</b><br>%{customdata[1]}<extra></extra>", // Showing explanation
          customdata: risks.map((risk: any, index: any) => [
            getRiskLabel(risk), // Risk category label
            explanation[index], // Explanation data
            id[index], // Segment ID
          ]), // We now send an array for customdata
          showlegend: false,
        },
        ...colorLegend.map((entry) => ({
          x: [null],
          y: [null],
          mode: "markers",
          marker: { color: entry.color, size: 15 },
          name: entry.label,
        })),
      ]}
      layout={{
        title: {
          text: loadedRoute.nickname || "Risk Chart",
          font: {
            size: 28, // Adjust the size value as needed
          },
        },
        xaxis: {
          // title: { text: "Route Segment" },
          rangeslider: {},
        },
        yaxis: {
          title: {
            text: "Risk Category",
            font: {
              size: 18, // Adjust the size value as needed
            },
          },
          range: [0, 6], // Set y-axis max to 6
        },
        showlegend: true,
        legend: {
          x: 1.0,
          y: 1.0,
          bgcolor: "rgba(255, 255, 255, 0)",
          bordercolor: "rgba(255, 255, 255, 0)",
          tracegroupgap: 0,
          itemsizing: "constant",
        },
        dragmode: "pan",
        hovermode: "closest",
        plot_bgcolor: "#fff",
        height: 375, // Set the height of the chart
        margin: {
          l: 100, // Left padding
          r: 100, // Right padding
          t: 60, // Top padding
          b: 20, // Bottom padding
        },
        autosize: true,
      }}
      config={{
        displaylogo: false,
        modeBarButtonsToRemove: [
          "lasso2d",
          "autoScale2d",
          "select2d",
          "zoom2d",
          "pan2d",
        ],
        scrollZoom: true,
      }}
      onClick={handleBarClick}
      onHover={handleBarHover}
      onUnhover={handleBarUnhover}
      onRelayout={handleRelayout}
      useResizeHandler={true}
      style={{ cursor: "pointer" }}
    />
  );
};

export default PlotlyBarCart;
export const simulateHover = (segmentId: any) => {
  // Cast segmentId to string
  const segmentIdString = String(segmentId);

  // Get the chart element
  const chartElement = document.getElementsByClassName("js-plotly-plot")[0];
  if (!chartElement) return;

  const plotData = (chartElement as any).data;
  const layout = (chartElement as any).layout;

  if (!plotData || plotData.length === 0 || !layout) return;

  // Extract the list of segmentIds (using customdata)
  const segmentIds: string[] =
    plotData[0]?.customdata?.map((data: any) => data[2]) || [];

  // Find the index of the segmentId
  const barIndex = segmentIds.findIndex((id: string) => id === segmentIdString);

  // If a matching segmentId is found, trigger the hover event
  if (barIndex !== -1) {
    // Ensure that we have both x and y values for the hover
    const xVal = plotData[0]?.x[barIndex];
    const yVal = plotData[0]?.y[barIndex];

    // Build the hoverData object correctly
    const hoverData = [
      {
        curveNumber: 0, // Assuming the data is from the first trace
        pointNumber: barIndex,
        xval: xVal,
        yval: yVal,
        // Only include subplot if necessary
      },
    ];

    // Trigger hover (without explicitly passing layout to avoid errors)
    try {
      (Plotly as any).Fx.hover(chartElement, hoverData);
    } catch (error) {
      console.error("Error triggering hover:", error);
    }
  } else {
    console.warn("Segment ID not found in the chart data.");
  }
};

// Function to unhover (hide the hover tooltip)
export const simulateUnhover = () => {
  // Get the chart element
  const chartElement = document.getElementsByClassName("js-plotly-plot")[0];
  if (!chartElement) return;

  // Remove any hover state (unhover action)
  try {
    (Plotly as any).Fx.unhover(chartElement);
  } catch (error) {
    console.error("Error triggering unhover:", error);
  }
};
