import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React from "react";

import { useAppDispatch } from "../../redux/hooks";
import { setChart } from "../../redux/reducers/chartDisplaySlice";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(11),
    marginRight: theme.spacing(11),
  },
  formControl: {
    margin: theme.spacing(3),
    alignItems: "center",
  },
  popover: {
    pointerEvents: "none",
    width: 350,
  },
  paper: {
    padding: theme.spacing(1),
    width: 700,
    // height: 150,
  },
  Mapbox_Traffic: {
    backgroundColor: "#06d6a0",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellAny: {
    backgroundColor: "#f94144",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellAtt: {
    backgroundColor: "#c44536",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellTmobile: {
    backgroundColor: "#f8961e",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellUsCellular: {
    backgroundColor: "#f9c74f",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  noCellVerizon: {
    backgroundColor: "#90be6d",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Current_Warnings: {
    backgroundColor: "#43aa8b",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Current_Watches: {
    backgroundColor: "#004e98",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  NWS_Wildfires: {
    backgroundColor: "#6a4c93",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldBridges: {
    backgroundColor: "#f94144",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldRailJunctions: {
    backgroundColor: "#6a4c93",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldTunnels: {
    backgroundColor: "#43aa8b",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hifldWeightStations: {
    backgroundColor: "#004e98",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  hospitals: {
    // backgroundColor: '#502873',
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: 5,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(12),
    marginRight: theme.spacing(12),
    border: "1px solid",
    // width: 400,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    display: "inline-block",
    width: "25%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 6, 0),
  },
  zoomTooltip: {
    fontSize: "14px",
  },
}));

const MapLayersTab: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // const classes = useStyles();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
    Cell_Coverage2: true,
    Incidents: true,
    Population: true,
    Rail_Junctions: true,
    Safe_Havens: true,
    Tunnels: true,
    Weather: true,
    Wildfires: true,
    checkbox1: true,
    LandscanUSA: false,
    LandscanGlobal: false,
    Mapbox_Traffic: false,
    Cell_Coverage: false,
    Cell_Coverage_ATT: false,
    Cell_Coverage_T_Mobile: false,
    Cell_Coverage_US_Cellular: false,
    Cell_Coverage_Verizon: false,
    NWS_Current_Warnings: false,
    NWS_Current_Watches: false,
    NWS_Wildfires: false,
    NWS_Weather_Radar: false,
    HIFLD_Hospitals: false,
    HIFLD_Police: false,
    HIFLD_Weigh_Stations: false,
    HIFLD_EMS: false,
    HIFLD_Tunnels: false,
    HIFLD_Rail_Juntions: false,
    HIFLD_Bridges: false,
  });

  const {
    checkbox1,
    LandscanUSA,
    LandscanGlobal,
    Mapbox_Traffic,
    Cell_Coverage,
    Cell_Coverage_ATT,
    Cell_Coverage_T_Mobile,
    Cell_Coverage_US_Cellular,
    Cell_Coverage_Verizon,
    NWS_Current_Warnings,
    NWS_Current_Watches,
    NWS_Wildfires,
    NWS_Weather_Radar,
    HIFLD_Hospitals,
    HIFLD_Police,
    HIFLD_Weigh_Stations,
    HIFLD_EMS,
    HIFLD_Tunnels,
    HIFLD_Rail_Juntions,
    HIFLD_Bridges,
  } = state;

  const resetLayerSwitches = () => {
    setState((prevState) => {
      return {
        ...prevState,
        LandscanUSA: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        LandscanGlobal: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Mapbox_Traffic: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_ATT: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_T_Mobile: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_US_Cellular: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        Cell_Coverage_Verizon: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Current_Warnings: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Current_Watches: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Wildfires: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        NWS_Weather_Radar: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Hospitals: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Police: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Weigh_Stations: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_EMS: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Tunnels: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Rail_Juntions: false,
      };
    });
    setState((prevState) => {
      return {
        ...prevState,
        HIFLD_Bridges: false,
      };
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    document.getElementById(event.target.name)!.click();
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [baseLayer, setBaseLayer] = React.useState<string | null>("Streets");

  const handleBaseLayerChange = (
    event: React.MouseEvent<HTMLElement>,
    newBaseLayer: string
  ) => {
    setBaseLayer(newBaseLayer);
    resetLayerSwitches();
    document.getElementById(newBaseLayer)!.click();
  };

  function runVulnerabilityCalc() {
    document.getElementById("vulnCalc")!.click();
  }

  function toggleChartView(value: any) {
    dispatch(setChart(value));
  }

  return (
    <div>
      <InputLabel>Base Layer</InputLabel>
      <ToggleButtonGroup
        value={baseLayer}
        exclusive
        onChange={handleBaseLayerChange}
      >
        <ToggleButton value="Streets">Streets</ToggleButton>
        <ToggleButton value="Satellite">Satellite</ToggleButton>
        <ToggleButton value="Hybrid">Hybrid</ToggleButton>
        <ToggleButton value="Navigation">Navigation</ToggleButton>
      </ToggleButtonGroup>
      <br></br>
      <br></br>
      <Divider />
      <List
        component="nav"
        className={classes.list}
        aria-label="mailbox folders"
      >
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.hospitals}
            src="/landscan_legend.png"
          />
          <ListItemText primary="Landscan USA Population (2021)" />
          {/* <InfoIcon
              id="ls-usa-info"
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            ></InfoIcon> */}
          <Tooltip
            title="Improved visibility at higher zoom levels"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={LandscanUSA}
            onChange={handleCheckboxChange}
            color="primary"
            name="LandscanUSA"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.Mapbox_Traffic}
            src="/transparent.png"
          />
          <ListItemText primary="Mapbox Live Traffic" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Mapbox_Traffic}
            onChange={handleCheckboxChange}
            color="primary"
            name="Mapbox_Traffic"
          />
        </ListItem>
        <Divider />
        {/* <ListItem>
            <Avatar
              alt="none"
              variant="rounded"
              className={classes.hospitals}
              src="/landscan_legend.png"
            />
            <ListItemText primary="Landscan Global Population (2022)" />
            <InfoIcon
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            ></InfoIcon>
            <Switch
              checked={LandscanGlobal}
              onChange={handleCheckboxChange}
              color="primary"
              name="LandscanGlobal"
            />
          </ListItem> */}
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.noCellAny}
            src="/transparent.png"
          />
          <ListItemText primary="No Cell Coverage (Any Provider)" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Cell_Coverage}
            onChange={handleCheckboxChange}
            color="primary"
            name="Cell_Coverage"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.noCellAtt}
            src="/transparent.png"
          />
          <ListItemText primary="No Cell Coverage (AT&T)" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Cell_Coverage_ATT}
            onChange={handleCheckboxChange}
            color="primary"
            name="Cell_Coverage_ATT"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.noCellTmobile}
            src="/transparent.png"
          />
          <ListItemText primary="No Cell Coverage (T-Mobile)" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Cell_Coverage_T_Mobile}
            onChange={handleCheckboxChange}
            color="primary"
            name="Cell_Coverage_T_Mobile"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.noCellUsCellular}
            src="/transparent.png"
          />
          <ListItemText primary="No Cell Coverage (US Cellular)" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Cell_Coverage_US_Cellular}
            onChange={handleCheckboxChange}
            color="primary"
            name="Cell_Coverage_US_Cellular"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.noCellVerizon}
            src="/transparent.png"
          />
          <ListItemText primary="No Cell Coverage (Verizon)" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={Cell_Coverage_Verizon}
            onChange={handleCheckboxChange}
            color="primary"
            name="Cell_Coverage_Verizon"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.NWS_Current_Warnings}
            src="/transparent.png"
          />
          <ListItemText primary="NWS Current Warnings" />
          <Switch
            checked={NWS_Current_Warnings}
            onChange={handleCheckboxChange}
            color="primary"
            name="NWS_Current_Warnings"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.NWS_Current_Watches}
            src="/transparent.png"
          />
          <ListItemText primary="NWS Current Watches" />
          <Switch
            checked={NWS_Current_Watches}
            onChange={handleCheckboxChange}
            color="primary"
            name="NWS_Current_Watches"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.NWS_Wildfires}
            src="/transparent.png"
          />
          <ListItemText primary="NWS Wildfires" />
          <Switch
            checked={NWS_Wildfires}
            onChange={handleCheckboxChange}
            color="primary"
            name="NWS_Wildfires"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.hospitals}
            src="/nws_weather_legend.png"
          />
          <ListItemText primary="NWS Weather Radar" />
          <Switch
            checked={NWS_Weather_Radar}
            onChange={handleCheckboxChange}
            color="primary"
            name="NWS_Weather_Radar"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.hospitals}
            src="https://cdn3.iconfinder.com/data/icons/medical-3-1/512/hospital_place-512.png"
          />
          <ListItemText primary="HIFLD Hospitals" />
          <Switch
            checked={HIFLD_Hospitals}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Hospitals"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.hospitals}
            src="https://static.thenounproject.com/png/1661277-200.png"
          />
          <ListItemText primary="HIFLD Police" />
          <Switch
            checked={HIFLD_Police}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Police"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            variant="rounded"
            className={classes.hospitals}
            src="https://cdn4.iconfinder.com/data/icons/health-medicine-volume-i/128/ems-symbol-1024.png"
          />
          <ListItemText primary="HIFLD EMS" />
          <Switch
            checked={HIFLD_EMS}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_EMS"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            className={classes.hifldWeightStations}
            src="/transparent.png"
          />
          <ListItemText primary="HIFLD Weigh Stations" />
          <Switch
            checked={HIFLD_Weigh_Stations}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Weigh_Stations"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            className={classes.hifldTunnels}
            src="/transparent.png"
          />
          <ListItemText primary="HIFLD Tunnels" />
          <Switch
            checked={HIFLD_Tunnels}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Tunnels"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            className={classes.hifldRailJunctions}
            src="/transparent.png"
          />
          <ListItemText primary="HIFLD Rail Crossings" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={HIFLD_Rail_Juntions}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Rail_Juntions"
          />
        </ListItem>
        <Divider />
        <ListItem>
          <Avatar
            alt="none"
            className={classes.hifldBridges}
            src="/transparent.png"
          />
          <ListItemText primary="HIFLD Bridges" />
          <Tooltip
            title="Requires a zoom level of 7 or higher"
            placement="right"
            classes={{ tooltip: classes.zoomTooltip }}
          >
            <InfoIcon style={{ fontSize: "medium" }}></InfoIcon>
          </Tooltip>
          <Switch
            checked={HIFLD_Bridges}
            onChange={handleCheckboxChange}
            color="primary"
            name="HIFLD_Bridges"
          />
        </ListItem>
        <Divider />
      </List>
    </div>
  );
};

export default MapLayersTab;
