import {
  MenuItem,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import LaunchIcon from "@material-ui/icons/Launch";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { Component } from "react";
import { connect } from "react-redux";

import { authFetch } from "../../auth";
import {
  setSelectedShipment,
  setUnits,
  setCampaigns,
  setEvents,
  setPositions,
  setLineStringCoordinates,
} from "../../redux/reducers/tstarSlice";

interface Props {
  selectedShipment: string;
  shipmentStatus: {
    unitState: string;
    lastContact: string;
  };
  events: {
    id: number;
    event_type: string;
    alarm: boolean;
    campaign_id: number;
    unit_id: number;
    generated_timestamp: string;
    received_timestamp: string;
    latitude: number;
    longitude: number;
    msg_data: {
      source_id: number;
      unit_name: string;
      sensor_name: string;
    };
    notification_sent: boolean;
  }[];
  campaigns: {
    id: number;
    name: string;
    last_activity: string;
    unit_id: number;
    unit_name: string;
    total_alerts: number;
    unacknowledged_alerts: number;
    unit_message_count: number;
    enabled: boolean;
    armed: boolean;
    archived: boolean;
    deleted: boolean;
    vehicle: any;
    cargo: any;
    watchers: any;
    users: any;
    fences: any;
  }[];
  units: {
    id: number;
    unit_serial: string;
    name: string;
    iridium_imei: string;
    provisioned: boolean;
    timestamp: string;
    campaign_id: number;
    campaign_name: string;
  }[];
  positions: Array<{
    id: string;
    unit_id: number;
    latitude: number;
    longitude: number;
    position: string;
    course: number;
    speed: number;
    generated_timestamp: string;
    received_timestamp: string;
    campaign_id: number;
    channel: string;
  }>;
  setSelectedShipment: (shipmentId: string) => void;
  setUnits: (units: any) => void;
  setCampaigns: (campaigns: any) => void;
  setEvents: (events: any) => void;
  setPositions: (positions: any) => void;
  setLineStringCoordinates: (coordinates: any) => void;
}

class TstarTab extends Component<Props> {
  componentDidMount() {
    this.fetchUnits();
    this.fetchCampaigns();
  }

  fetchUnits = async () => {
    try {
      const unitsResponse = await authFetch("tstar/units", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const unitsResult = await unitsResponse.json();
      console.log(unitsResult);
      this.props.setUnits(unitsResult);
    } catch (error) {
      console.error("Error fetching units: ", error);
    }
  };

  fetchCampaigns = async () => {
    try {
      const campaignsResponse = await authFetch("tstar/campaigns", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const campaignsResult = await campaignsResponse.json();
      console.log(campaignsResult);
      this.props.setCampaigns(campaignsResult);
    } catch (error) {
      console.error("Error fetching campaigns: ", error);
    }
  };

  handleShipmentChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedShipment = event.target.value as string;
    this.props.setSelectedShipment(selectedShipment);

    try {
      const positionsResponse = await authFetch(
        `tstar/positions?campaign_id=${selectedShipment}&hours=0.5`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const positionsResult = await positionsResponse.json();
      console.log(positionsResult);
      this.props.setPositions(positionsResult);

      const lineStringCoordinates = this.convertToLineString(positionsResult);
      this.props.setLineStringCoordinates(lineStringCoordinates);
      console.log(lineStringCoordinates);

      const eventsResponse = await authFetch(
        `tstar/events?campaign_id=${selectedShipment}&hours=0.5`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const eventsResult = await eventsResponse.json();
      console.log(eventsResult);
      this.props.setEvents(eventsResult);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  handleRefresh = () => {
    this.fetchUnits();
    this.fetchCampaigns();
    if (this.props.selectedShipment != "0") {
      this.handleShipmentChange({
        target: { value: this.props.selectedShipment },
      } as React.ChangeEvent<{ value: unknown }>);
    }
  };

  handleClear = () => {
    this.props.setSelectedShipment("0");
    this.props.setEvents([]);
    this.props.setPositions([]);
    this.props.setLineStringCoordinates([]);
  };

  convertToLineString = (positions: any) => {
    return positions.map((position: any) => [
      position.longitude,
      position.latitude,
    ]);
  };

  handleViewOnTStar = () => {
    window.open("https://tstar3.com/#/dashboard", "_blank");
  };

  render() {
    const {
      selectedShipment,
      shipmentStatus,
      events,
      campaigns,
      positions,
      units,
    } = this.props;

    return (
      <div style={{ padding: "50px" }}>
        <div style={{ marginBottom: "50px" }}>
          <FormControl style={{ width: 200 }}>
            <Select
              value={selectedShipment || ""}
              onChange={this.handleShipmentChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Your Campaign
              </MenuItem>
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: "10px" }}>
            <Button
              size="small"
              variant="contained"
              color="default"
              onClick={this.handleRefresh}
              style={{ marginRight: "10px" }}
              startIcon={<RefreshIcon />}
            >
              Refresh
            </Button>
            <Button
              size="small"
              variant="contained"
              color="default"
              onClick={this.handleClear}
              style={{ marginRight: "10px" }}
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
            <Button
              size="small"
              variant="contained"
              color="default"
              onClick={this.handleViewOnTStar}
              style={{ marginRight: "10px" }}
              startIcon={<LaunchIcon />}
            >
              T-Star
            </Button>
          </div>
        </div>
        {selectedShipment != "0" && (
          <div style={{ marginBottom: "50px" }}>
            <h2>Unit Status</h2>
            {campaigns.length > 0 ? (
              <>
                <p>Unit Name: {campaigns[0].unit_name || "N/A"}</p>
                <p>Serial: {units[0].unit_serial || "N/A"}</p>
                <p>Armed: {campaigns[0].armed ? "True" : "False"}</p>
                <p>Last Activity: {campaigns[0].last_activity || "N/A"}</p>
              </>
            ) : (
              <p>No campaigns available</p>
            )}
          </div>
        )}
        {selectedShipment != "0" && (
          <div>
            <h2>Events</h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sensor</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Position</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event: any, index: any) => (
                    <TableRow key={index}>
                      <TableCell>{event.msg_data.sensor_name}</TableCell>
                      <TableCell>{event.generated_timestamp}</TableCell>
                      <TableCell>{event.event_type}</TableCell>
                      <TableCell>
                        {event.latitude.toFixed(4)},{" "}
                        {event.longitude.toFixed(4)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedShipment: state.tstar.selectedShipment,
  shipmentStatus: state.tstar.shipmentStatus,
  events: state.tstar.events,
  campaigns: state.tstar.campaigns,
  units: state.tstar.units,
  positions: state.tstar.positions,
  lineStringCoordinates: state.tstar.lineStringCoordinates,
});

const mapDispatchToProps = {
  setSelectedShipment,
  setUnits,
  setCampaigns,
  setEvents,
  setPositions,
  setLineStringCoordinates,
};

export default connect(mapStateToProps, mapDispatchToProps)(TstarTab);
