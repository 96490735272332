import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { client } from "../../utils/client.js";

const initialState = {
  risk: [],
  status: "idle",
  error: null,
};

export const fetchRisk = createAsyncThunk("risk/fetchRisk", async () => {
  const response = await client.get("./route_risk");
  return response;
});

const routeRiskSlice = createSlice({
  name: "risk",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRisk.pending.type]: (state, action) => {
      state.status = "loading";
    },
    [fetchRisk.fulfilled.type]: (state, action) => {
      state.status = "succeeded";
      state.risk = action.payload;
    },
    [fetchRisk.rejected.type]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export default routeRiskSlice.reducer;

export const getRisk = (state: any) => state.risk.risk;
