import React from "react";
import Grid from "@material-ui/core/Grid";
import { useAppSelector } from "../../../../../../redux/hooks";

interface IRiskSettingFooterViewProps {
  showInfo: boolean,
  getControlDetails: any
}

const RiskSettingFooterView: React.FC<IRiskSettingFooterViewProps> = (props: IRiskSettingFooterViewProps) => {
  const controlDetails: any = useAppSelector(props.getControlDetails);

  if (!props.showInfo) return null;
  return (
    <div>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="space-evenly"
      >
        <Grid item xs={3} style={{ marginTop: 0 }}>
          <div style={{ fontSize: "0.75rem", cursor: "default" }}>
            Acceptable <br /> (Route Change Optional)
          </div>
        </Grid>
        <Grid item xs={4} style={{ marginTop: 0 }}>
          <p style={{ fontSize: "0.75rem", cursor: "default" }}>
            Undesirable <br /> (Route Change Preferred)
          </p>
        </Grid>
        <Grid item xs={3} style={{ marginTop: 0 }}>
          <p style={{ fontSize: "0.75rem", cursor: "default" }}>
            Highly Undesirable (Route Change Highly Preferred)
          </p>
        </Grid>
        <Grid item xs={2} style={{ marginTop: 0 }}>
          <p style={{ fontSize: "0.75rem", cursor: "default" }}>
            No Go (Required)
          </p>
        </Grid>
      </Grid>
      <div style={{ fontSize: "0.75rem", cursor: "default" }}>
        {controlDetails?.["sentence_1"]}{" "}
        {controlDetails?.["sentence_2"]}{" "}
        {controlDetails?.["sentence3"]}
      </div>
    </div>
  )
};

export default RiskSettingFooterView;
