import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState } from "react";

import { authFetch } from "../../auth";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { setBackdrop } from "../../redux/reducers/backdropSlice";
import {
  getBridgeOverpassSlider,
  getBridgeOverWaterSlider,
  getBridgeOverLandSlider,
  getCurvatureSlider,
  getPopulationRuralSlider,
  getPopulationSuburbanSlider,
  getPopulationUrbanSlider,
  getTunnelSlider,
  getRailSlider,
  setBridgeOverpassSlider,
  setBridgeOverWaterSlider,
  setBridgeOverLandSlider,
  setCurvatureSlider,
  setPopulationRuralSlider,
  setPopulationSuburbanSlider,
  setPopulationUrbanSlider,
  setTunnelSlider,
  setRailSlider,
  getBridgeOverLandVisibility,
  getBridgeOverpassVisibility,
  getCurvatureVisibility,
  getPopulationRuralVisibility,
  getPopulationSuburbanVisibility,
  getPopulationUrbanVisibility,
  getBridgeOverWaterVisibility,
  getTunnelVisibility,
  getRailVisibility,
} from "../../redux/reducers/sliderSlice";
import {
  setRoute,
  getRoute,
  getRouteId,
  setRouteId,
  setRouteName,
} from "../../redux/reducers/uploadedRouteSlice";
import TestRoute from "../testRoutes/test_route_id-1.json";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function RouteUpload() {
  const routeId = useAppSelector(getRouteId);
  const loadedRoute = useAppSelector(getRoute).features[0].properties.route_id;
  const route1 = useAppSelector(getRoute);

  const bridgeOverpassSliderValue = useAppSelector(getBridgeOverpassSlider);
  const bridgeOverWaterSliderValue = useAppSelector(getBridgeOverWaterSlider);
  const bridgeOverLandSliderValue = useAppSelector(getBridgeOverLandSlider);
  const curvatureSliderValue = useAppSelector(getCurvatureSlider);
  const populationRuralSliderValue = useAppSelector(getPopulationRuralSlider);
  const populationSuburbanSliderValue = useAppSelector(
    getPopulationSuburbanSlider
  );
  const populationUrbanSliderValue = useAppSelector(getPopulationUrbanSlider);
  const tunnelSliderValue = useAppSelector(getTunnelSlider);
  const railSliderValue = useAppSelector(getRailSlider);

  const bridgeOverLandVisibility = useAppSelector(getBridgeOverLandVisibility);
  const bridgeOverpassVisibility = useAppSelector(getBridgeOverpassVisibility);
  const curvatureVisibility = useAppSelector(getCurvatureVisibility);
  const populationRuralVisibility = useAppSelector(
    getPopulationRuralVisibility
  );
  const populationSuburbanVisibility = useAppSelector(
    getPopulationSuburbanVisibility
  );
  const populationUrbanVisibility = useAppSelector(
    getPopulationUrbanVisibility
  );
  const bridgeOverWaterVisibility = useAppSelector(
    getBridgeOverWaterVisibility
  );
  const tunnelVisibility = useAppSelector(getTunnelVisibility);
  const railVisibility = useAppSelector(getRailVisibility);

  let singleRoute = false;

  const dispatch = useAppDispatch();
  const [uploadError, setUploadError] = useState(false);

  function toggleBackdropOn() {
    dispatch(setBackdrop({ status: "true", message: "Loading map layer" }));
  }
  function toggleBackdropOff() {
    dispatch(setBackdrop({ status: "false" }));
  }

  function geocode() {
    console.log("geocode");
    let origin = localStorage.getItem("origin");
    let destination = localStorage.getItem("destination");
    const waypointsString = localStorage.getItem("waypoints");
    const waypoints = waypointsString ? JSON.parse(waypointsString) : [];

    console.log(origin, destination, waypoints);

    if (origin && destination) {
      let originSplit, destinationSplit;

      if (origin) {
        origin = origin.substring(1, origin.length - 1);
        originSplit = origin.split(",");
        console.log(originSplit);
      }

      if (destination) {
        destination = destination.substring(1, destination.length - 1);
        destinationSplit = destination.split(",");
        console.log(destinationSplit);
      }

      // Function to limit decimal places
      const limitDecimals = (num: number, decimals: number) => {
        const rounded = parseFloat(num.toFixed(decimals));
        return rounded.toString();
      };

      const routePoints = [
        originSplit,
        ...waypoints.map((wp: { lng: number; lat: number }) => [
          limitDecimals(wp.lng, 5),
          limitDecimals(wp.lat, 5),
        ]),
        destinationSplit,
      ];

      console.log(routePoints);

      // generating route with lat/long
      generateRoute(...routePoints);

      // resetting controls (example reset, adjust as needed)
      dispatch(setBridgeOverpassSlider(2.2));
      dispatch(setBridgeOverWaterSlider(3));
      dispatch(setBridgeOverLandSlider(2.3));
      dispatch(setCurvatureSlider(5.6));
      dispatch(setPopulationRuralSlider(2));
      dispatch(setPopulationSuburbanSlider(3.5));
      dispatch(setPopulationUrbanSlider(5));
      dispatch(setTunnelSlider(5.5));
      dispatch(setRailSlider(6));
    } else {
      alert("Please select an origin and destination.");
    }
  }

  function generateRoute(...routePoints: any[]) {
    singleRoute = false;

    dispatch(
      setBackdrop({
        status: "true",
        message: "Generating your route. This may take a moment",
      })
    );

    // Initialize the body object
    const body: { [key: string]: any } = {};

    // Process each route point dynamically
    routePoints.forEach((point, index) => {
      if (point) {
        const xKey = index === 0 ? "org_x" : `dest${index}_x`;
        const yKey = index === 0 ? "org_y" : `dest${index}_y`;

        body[xKey] = parseFloat((point[0] as any).replaceAll(" ", ""));
        body[yKey] = parseFloat((point[1] as any).replaceAll(" ", ""));
      }
    });

    console.log(body);

    const endpoint =
      Object.keys(body).length > 4
        ? "generate_waypoints_route"
        : "generate_osm_route";

    //testing mult-route endpoint
    authFetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) {
        dispatch(setBackdrop({ status: "false" }));
        alert(
          "Failed to generate route(s). Please select a new start location and destination and try again."
        );
      }
      if (res.ok) {
        const returnedRouteId = res.json(); // Route id returned from db
        returnedRouteId.then(function (generated_route_id: any) {
          const route1 = generated_route_id;
          dispatch(setRouteId(route1));
          console.log(generated_route_id);

          loadRoute(route1);
        });
      }
    });
  }

  function runVulnerabilityCalc() {
    console.log(routeId);
    loadRoute(routeId, "Calculating risk");
  }

  function loadRoute(routeId: any, message?: any) {
    if (!message) {
      message = "Loading your route";
    }
    // dispatch(setRouteId(routeId));
    let routeName = "";
    //clear old routes out
    dispatch(setRoute(TestRoute));

    //how we handle if loading from dropdown list
    if (routeId == "0") {
      console.log(document.getElementById("loadRoute") as HTMLInputElement);
      routeId = (document.getElementById("loadRoute") as HTMLInputElement)
        .value;
      routeName = (document.getElementById("loadRoute") as HTMLInputElement)
        .name;
    }
    console.log(routeId);
    console.log(routeName);
    dispatch(setRouteId(routeId));

    const with_scores = true;

    // };

    //hardcoding this for Jonathan's new function:
    const body = {
      route_id: routeId,
      likert_scores: {
        bridge_overpass: bridgeOverpassVisibility
          ? bridgeOverpassSliderValue
          : 0, // Check visibility
        bridge_overwater: bridgeOverWaterVisibility
          ? bridgeOverWaterSliderValue
          : 0, // Check visibility
        bridge_overland: bridgeOverLandVisibility
          ? bridgeOverLandSliderValue
          : 0, // Check visibility
        curvature: curvatureVisibility ? curvatureSliderValue : 0, // Check visibility
        rural_population: populationRuralVisibility
          ? populationRuralSliderValue
          : 0, // Check visibility
        suburban_population: populationSuburbanVisibility
          ? populationSuburbanSliderValue
          : 0, // Check visibility
        urban_population: populationUrbanVisibility
          ? populationUrbanSliderValue
          : 0, // Check visibility
        tunnel: tunnelVisibility ? tunnelSliderValue : 0, // Check visibility
        rail: railVisibility ? railSliderValue : 0, // Check visibility
      },
    };

    // let body = {'route_id': routeId, 'with_scores': true}

    dispatch(setBackdrop({ status: "true", message: message }));

    if (document.getElementById("reset_route_checkbox")) {
      document.getElementById("reset_route_checkbox")!.click();
    }

    //route 1
    authFetch("fetch_route", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) {
        dispatch(setBackdrop({ status: "false" }));
        alert(
          "Failed to generate route(s). Please select a new start location and destination and try again."
        );
        // throw new Error(res.statusText);
      }

      const returnedRoute = res.json(); // Route returned from db
      returnedRoute.then(function (result: any) {
        console.log(result.features);
        //temporary check in case this is null

        const features = result.features;

        for (let i = 0; i < features.length; i++) {
          const vul_score = features[i].properties.risk;
          if (vul_score < 1 || !vul_score) {
            features[i].properties["color"] = "#4575b4"; //#00b25d
          }
          if (vul_score >= 1 && vul_score < 2) {
            features[i].properties["color"] = "#91bfdb"; //007b01
          }
          if (vul_score >= 2 && vul_score < 3) {
            features[i].properties["color"] = "#e0f3f8"; //c4ff00
          }
          if (vul_score >= 3 && vul_score < 4) {
            features[i].properties["color"] = "#ffffbf"; //f1da04
          }
          if (vul_score >= 4 && vul_score < 5) {
            features[i].properties["color"] = "#fee090"; //fda206
          }
          if (vul_score >= 5 && vul_score < 6) {
            features[i].properties["color"] = "#fc8d59"; //f46311
          }
          if (vul_score == 6) {
            features[i].properties["color"] = "#d73027"; //fd0606
          }

          const normalized_vul_score =
            features[i].properties.vulnerability_score_normalized;
          if (normalized_vul_score < 1) {
            features[i].properties["normalized_color"] = "#00b8ff";
          }
          if (normalized_vul_score >= 1 && normalized_vul_score <= 1.99) {
            features[i].properties["normalized_color"] = "#3445c3";
          }
          if (normalized_vul_score >= 2 && normalized_vul_score <= 2.99) {
            features[i].properties["normalized_color"] = "#26477e";
          }
          if (normalized_vul_score > 2.99) {
            features[i].properties["normalized_color"] = "#1e225c";
          }
        }
        dispatch(setRoute(result));
        // dispatch(setRouteName(result.features[0].properties.nickname));
        dispatch(setRouteName(result.nickname || "Generated Route"));
        dispatch(setBackdrop({ status: "false" }));

        //TODO: This is a temporary fix for pre-loaded routes to only load the single route at a time

        //Need to test if this works properly with generated routes
        if (routeId > 5 && routeName == "" && singleRoute == false) {
        } //end if
      });
    });
  }

  return (
    <React.Fragment>
      <Button
        style={{ visibility: "hidden" }}
        id="loadRoute"
        value="0"
        color="inherit"
        onClick={() => loadRoute("0")}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="toggleView"
        color="inherit"
        onClick={() => loadRoute("1")}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="vulnCalc"
        color="inherit"
        onClick={() => runVulnerabilityCalc()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="generateRoute"
        color="inherit"
        onClick={() => geocode()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="toggleBackdropOn"
        color="inherit"
        onClick={() => toggleBackdropOn()}
      ></Button>
      <Button
        style={{ visibility: "hidden" }}
        id="toggleBackdropOff"
        color="inherit"
        onClick={() => toggleBackdropOff()}
      ></Button>
    </React.Fragment>
  );
}

export default RouteUpload;
