import React, { useState } from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import RiskSettingHeaderView from "./RiskSettingHeaderView/RiskSettingHeaderView";
import RiskSettingSliderView from "./RiskSettingSliderView/RiskSettingSliderView";
import RiskSettingFooterView from "./RiskSettingFooterView/RiskSettingFooterView";
import "./RiskSettingView.css";

interface IRiskSettingViewProps {
  title: string,
  markDataIndex: number, 
  getVisible: any,
  toggleVisible: Function,
  getControlDetails: any,
  getValue: any,
  setValue: Function,
  marks: {value: number, label?: string}[],
}

const RiskSettingView: React.FC<IRiskSettingViewProps> = (props: IRiskSettingViewProps) => {
  // State
  const [showInfo, setShowInfo] = useState(false);
  const visible: boolean = useAppSelector(props.getVisible);

  const toggleShowInfo = () => {
    setShowInfo(!showInfo);
  }

  return (
    <div
      className="risk-setting-view"
      style={{
        opacity: visible ? 1 : 0.5,
        pointerEvents: visible ? "auto" : "none",
      }}
    >
      <RiskSettingHeaderView
        title={props.title}
        getVisible={props.getVisible}
        toggleVisible={props.toggleVisible}
        showInfo={showInfo}
        toggleShowInfo={toggleShowInfo}
      />      
      <RiskSettingSliderView
        title={props.title}
        markDataIndex={props.markDataIndex}
        getVisible={props.getVisible}
        getControlDetails={props.getControlDetails}
        getValue={props.getValue}
        setValue={props.setValue} 
        marks={props.marks}
      />
      <RiskSettingFooterView showInfo={showInfo} getControlDetails={props.getControlDetails} />
    </div>
  )
};

export default RiskSettingView;
