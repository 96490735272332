import React from "react";
import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useStyles } from "../../../../../../consts/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import "./RiskSettingHeaderView.css";

interface IRiskSettingHeaderViewProps {
  title: string,
  getVisible: any,
  toggleVisible: Function,
  showInfo: boolean,
  toggleShowInfo: Function
}

const RiskSettingHeaderView: React.FC<IRiskSettingHeaderViewProps> = (props: IRiskSettingHeaderViewProps) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const visible = useAppSelector(props.getVisible);

  // Toggle visibility
  const toggleVisibilty = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(props.toggleVisible());
  };

  return (
    <div className="risk-setting-header-view">
        <Tooltip
          placement="right"
          classes={{ tooltip: styles.zoomTooltip }}
          title={
            visible
              ? "Including this risk factor in calculations"
              : "Excluding this risk factor from calculations"
          }
          arrow
        >
          <IconButton className="risk-setting-visibility-icon-button" size="small" onClick={toggleVisibilty}>
            {visible ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
          </IconButton>
        </Tooltip>
        <Typography component="span" gutterBottom style={{ display: "inline-block" }}>
          {props.title}
        </Typography>
        <Button
          className="risk-setting-info-button"
          color="primary"
          size="small"
          onClick={() => props.toggleShowInfo()}
          disabled={!visible}
        >
          {props.showInfo ? "Hide Info" : "More Info"}
        </Button>
      </div>
  )
};

export default RiskSettingHeaderView;
