import React from "react";
import { Button, IconButton, InputLabel, Tooltip } from "@material-ui/core";
import { useStyles } from "../../../consts/styles";
import { InfoOutlined, UnfoldLess, UnfoldMore } from "@material-ui/icons";
import RiskSettingsGroupView from "./RiskSettingsGroupView/RiskSettingsGroupView";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getBridgeGroupExpanded, getBridgeOverLandSlider, getBridgeOverLandSliderDetails, getBridgeOverLandVisibility, getBridgeOverpassSlider, getBridgeOverpassSliderDetails, getBridgeOverpassVisibility, getBridgeOverWaterSlider, getBridgeOverWaterSliderDetails, getBridgeOverWaterVisibility, getCurvatureSlider, getCurvatureSliderDetails, getCurvatureVisibility, getPopulationGroupExpanded, getPopulationRuralSlider, getPopulationRuralSliderDetails, getPopulationRuralVisibility, getPopulationSuburbanSlider, getPopulationSuburbanSliderDetails, getPopulationSuburbanVisibility, getPopulationUrbanSlider, getPopulationUrbanSliderDetails, getPopulationUrbanVisibility, getRailSlider, getRailSliderDetails, getRailVisibility, getRoadGroupExpanded, getTunnelSlider, getTunnelSliderDetails, getTunnelVisibility, setBridgeGroupExpanded, setBridgeOverLandSlider, setBridgeOverpassSlider, setBridgeOverWaterSlider, setCurvatureSlider, setPopulationGroupExpanded, setPopulationRuralSlider, setPopulationSuburbanSlider, setPopulationUrbanSlider, setRailSlider, setRoadGroupExpanded, setTunnelSlider, toggleBridgeGroupExpanded, toggleBridgeOverLandVisibility, toggleBridgeOverpassVisibility, toggleBridgeOverWaterVisibility, toggleCurvatureVisibility, togglePopulationGroupExpanded, togglePopulationRuralVisibility, togglePopulationSuburbanVisibility, togglePopulationUrbanVisibility, toggleRailVisibility, toggleRoadGroupExpanded, toggleTunnelVisibility } from "../../../redux/reducers/sliderSlice";
import RiskSettingView from "./RiskSettingsGroupView/RiskSettingView/RiskSettingView";
import "./RiskSettingsView.css";

const RiskSettingsView: React.FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const runVulnerabilityCalc = () => {
    document.getElementById("vulnCalc")!.click();
  }

  const expandAllGroups = () => {
    dispatch(setBridgeGroupExpanded(true));
    dispatch(setPopulationGroupExpanded(true));
    dispatch(setRoadGroupExpanded(true));
  }

  const collapseAllGroups = () => {
    dispatch(setBridgeGroupExpanded(false));
    dispatch(setPopulationGroupExpanded(false));
    dispatch(setRoadGroupExpanded(false));
  }

  return (
    <div className="risk-settings-view">
      <div className="risk-avoidance-view">
        <InputLabel>Risk Avoidance Factor (Likert Scale)</InputLabel>
        <Tooltip
          title={
            <>
              1 - 3 <b>Acceptable</b>
              <br />4 - 6 <b>Undesirable</b>
              <br />7 - 9 <b>Highly Undesirable</b>
              <br />
              10 <b>Absolutely No-Go</b>
            </>
          }
          placement="right"
          classes={{ tooltip: styles.zoomTooltip }}
        >
          <InfoOutlined aria-haspopup="true" style={{ marginLeft: 8, fontSize: "1rem" }} />
        </Tooltip>
        <div className="risk-settings-fold-controls">
          <Tooltip
            placement="top"
            classes={{ tooltip: styles.zoomTooltip }}
            title="Expand All"
            arrow
          >
            <IconButton className="risk-settings-fold-button" size="small" onClick={expandAllGroups}>
              <UnfoldMore fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip
            placement="top"
            classes={{ tooltip: styles.zoomTooltip }}
            title="Collapse All"
            arrow
          >
            <IconButton className="risk-settings-fold-button" size="small" onClick={collapseAllGroups}>
              <UnfoldLess fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div>
        <RiskSettingsGroupView
          title="Bridge Settings"
          getExpanded={getBridgeGroupExpanded}
          toggleExpanded={toggleBridgeGroupExpanded}
        >
          <RiskSettingView
            title="Bridge Over Land"
            markDataIndex={2}
            getVisible={getBridgeOverLandVisibility}
            toggleVisible={toggleBridgeOverLandVisibility}
            getControlDetails={getBridgeOverLandSliderDetails}
            getValue={getBridgeOverLandSlider}
            setValue={setBridgeOverLandSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 2.3, label: "" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Bridge Over Water"
            markDataIndex={2}
            getVisible={getBridgeOverWaterVisibility}
            toggleVisible={toggleBridgeOverWaterVisibility}
            getControlDetails={getBridgeOverWaterSliderDetails}
            getValue={getBridgeOverWaterSlider}
            setValue={setBridgeOverWaterSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Bridge Overpass / Underpass"
            markDataIndex={2}
            getVisible={getBridgeOverpassVisibility}
            toggleVisible={toggleBridgeOverpassVisibility}
            getControlDetails={getBridgeOverpassSliderDetails}
            getValue={getBridgeOverpassSlider}
            setValue={setBridgeOverpassSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 2.2 },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
        </RiskSettingsGroupView>

        <RiskSettingsGroupView
          title="Population Settings"
          getExpanded={getPopulationGroupExpanded}
          toggleExpanded={togglePopulationGroupExpanded}
        >
          <RiskSettingView
            title="Rural"
            markDataIndex={2}
            getVisible={getPopulationRuralVisibility}
            toggleVisible={togglePopulationRuralVisibility}
            getControlDetails={getPopulationRuralSliderDetails}
            getValue={getPopulationRuralSlider}
            setValue={setPopulationRuralSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              {
                value: useAppSelector(getPopulationRuralSliderDetails)?.["mid_median"] || 2,
              },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Suburban"
            markDataIndex={3}
            getVisible={getPopulationSuburbanVisibility}
            toggleVisible={togglePopulationSuburbanVisibility}
            getControlDetails={getPopulationSuburbanSliderDetails}
            getValue={getPopulationSuburbanSlider}
            setValue={setPopulationSuburbanSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              {
                value: useAppSelector(getPopulationSuburbanSliderDetails)?.["mid_median"] || 3.5,
              },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Urban"
            markDataIndex={5}
            getVisible={getPopulationUrbanVisibility}
            toggleVisible={togglePopulationUrbanVisibility}
            getControlDetails={getPopulationUrbanSliderDetails}
            getValue={getPopulationUrbanSlider}
            setValue={setPopulationUrbanSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              {
                value: useAppSelector(getPopulationUrbanSliderDetails)?.["mid_median"] || 3.5,
              },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
        </RiskSettingsGroupView>

        <RiskSettingsGroupView
          title="Road Settings"
          getExpanded={getRoadGroupExpanded}
          toggleExpanded={toggleRoadGroupExpanded}
        >
          <RiskSettingView
            title="Railroad Crossing"
            markDataIndex={5}
            getVisible={getRailVisibility}
            toggleVisible={toggleRailVisibility}
            getControlDetails={getRailSliderDetails}
            getValue={getRailSlider}
            setValue={setRailSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Tunnels (small or large)"
            markDataIndex={5}
            getVisible={getTunnelVisibility}
            toggleVisible={toggleTunnelVisibility}
            getControlDetails={getTunnelSliderDetails}
            getValue={getTunnelSlider}
            setValue={setTunnelSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 5.5 },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
          <RiskSettingView
            title="Winding Narrow Roads"
            markDataIndex={5}
            getVisible={getCurvatureVisibility}
            toggleVisible={toggleCurvatureVisibility}
            getControlDetails={getCurvatureSliderDetails}
            getValue={getCurvatureSlider}
            setValue={setCurvatureSlider}
            marks={[
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
              { value: 5.6 },
              { value: 6, label: "6" },
              { value: 7, label: "7" },
              { value: 8, label: "8" },
              { value: 9, label: "9" },
              { value: 10, label: "10" },
            ]}
          />
        </RiskSettingsGroupView>
      </div>
      <div className="risk-settings-run-calculation-button">
        <Button
          className="risk-settings-run-calculation-button"
          variant="contained"
          id="runVulBtn"
          color="primary"
          onClick={runVulnerabilityCalc}
        >
          Run Calculator
        </Button>
      </div>
    </div>
  );
};

export default RiskSettingsView;
